<template>
	<div class="d-flex">
		<b-button
			variant="info"
			size="sm"
			class="mr-1"
			:disabled="showView != 1"
			@click="setRoute('detail')"
			v-show="hideView != 1"
		>
			<b-icon-info-square-fill></b-icon-info-square-fill>
			<span class="ml-1" v-text="$t('COMMON.BTN_VIEW')"></span>
		</b-button>
		<b-button
			variant="primary"
			size="sm"
			class="mr-1"
			:disabled="showEdit != 1"
			@click="setRoute('edit')"
			v-show="hideEdit != 1"
		>
			<b-icon-pencil-square></b-icon-pencil-square>
			<span class="ml-1" v-text="$t('COMMON.BTN_EDIT')"></span>
		</b-button>
		<slot name="otherButtonSlot"></slot>
		<b-button
			variant="danger"
			size="sm"
			class="summaryDeleteBTN mr-1"
			:disabled="showDelete != 1"
			@click="$emit('deleteData')"
			v-show="hideDeleteButton != 1"
		>
			<b-icon-trash-fill></b-icon-trash-fill>
			<span class="ml-1" v-text="$t('COMMON.BTN_DELETE')"></span>
		</b-button>
	</div>
</template>

<script>
export default {
	name: "GimSummaryPage",
	props: {
		data: {
			type: Object,
			default() {
				return {};
			},
		},
		routeUrl: {
			type: String,
			default: null,
		},
		idKey: {
			type: String,
			default: "id",
		},
		hideDeleteButton: {
			type: Boolean,
			default: false,
		},
		id: {
			type: Number,
			default() {
				return 0;
			},
		},
		showView: {
			type: Number,
			default: 0,
		},
		showEdit: {
			type: Number,
			default: 0,
		},
		showDelete: {
			type: Number,
			default: 0,
		},
		hideView: null,
		hideEdit: null,
		hideDelete: null,
		first_params: null,
		module_name: {
			type: String,
			default: null,
		},
		query_param: {
			type: String,
			default: null,
		},
	},
	methods: {
		setRoute(path) {
			let state = true;
			if (
				!this.$CoreSettingsJs.emptyStringValidation(this.module_name) &&
				["do", "tt_summary"].includes(this.module_name)
			) {
				this.$emit("viewEditValidation", (result) => (state = result));
			}

			if (state) {
				let url = "";
				if (!this.$CoreSettingsJs.emptyObjectValidation(this.data)) {
					if (this.routeUrl == "/cost/salesman-cost-group") {
						url = `${this.routeUrl}/${path}/${this.data.main_route_id}/${this.data.shipment_type_id}/${this.data.shipment_mode_id}`;
					} else if (this.routeUrl == "/master-data/tt-daily-rate") {
						url = `${this.routeUrl}/${path}/${this.data.currency_id}/${this.data.effective_date}`;
					} else if (this.routeUrl == "/master-data/tt-spread-rate") {
						url = `${this.routeUrl}/${path}/${this.data.currency_id}`;
					} else if (this.routeUrl == "/customer-management/customer") {
						url = `${this.routeUrl}/${path}/${this.data.cus_id}${
							this.data.company_linkage_id ? `/` + this.data.company_linkage_id : ""
						}`;
					} else if (
						!this.$CoreSettingsJs.emptyStringValidation(this.$route.matched[2].meta) &&
						!this.$CoreSettingsJs.emptyStringValidation(
							this.$route.matched[2].meta["page"]
						) &&
						this.$route.matched[2].meta["page"] == "menu_item"
					) {
						url = `${this.routeUrl}/${path}/${this.first_params}/${this.data.id}`;
					} else {
						if (this.id != 0) {
							url = `${this.routeUrl}/${path}/${this.id}`;
						} else if (!this.$CoreSettingsJs.emptyObjectValidation(this.data)) {
							url = `${this.routeUrl}/${path}/${this.data[this.idKey]}`;
						} else {
							url = null;
						}
					}
				} else {
					url = null;
				}
				if (!this.$CoreSettingsJs.emptyStringValidation(url)) {
					if (!this.$CoreSettingsJs.emptyObjectValidation(this.query_param)) {
						url = `${url}?${this.query_param}`;
					}
					window.location = url;
				}
				return url;
			} else {
				return null;
			}
		},
	},
};
</script>
