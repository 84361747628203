var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{class:_vm.admin_portal == 1 ? `phoneFieldsAdmin` : `phoneFields`},[_c('b-col',{staticClass:"cols"},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.hideLabel == 0),expression:"hideLabel == 0"}],staticClass:"text-nowrap mb-0",class:_vm.required_class ? `required-fields` : ``,domProps:{"textContent":_vm._s(_vm.label)}}),_c('div',{staticClass:"d-flex flex-nowrap"},[(_vm.show_edit_button == 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-icon mr-1",attrs:{"size":"sm","variant":_vm.block == 1 ? `success` : `secondary`,"title":_vm.block == 1 ? _vm.$t('COMMON.BTN_ENABLE_EDIT') : _vm.$t('COMMON.BTN_DISABLE_EDIT')},on:{"click":function($event){return _vm.$emit('blockBtn')}}},[_c('b-icon-pencil-fill')],1):_vm._e(),_c('b-input-group',{staticClass:"flex-grow-1 flex-nowrap"},[(!_vm.hideCallingCode)?_c('phoneCodeSelect',{attrs:{"data":_vm.callingCode,"options":_vm.callingCodeSelection,"is_view":_vm.is_view == 1 || _vm.disabled_calling_code == 1 ? 1 : 0,"required_class":_vm.admin_portal == 1 ? _vm.required_class : null,"idx":_vm.idx},on:{"changeValue":_vm.changeValue}}):_vm._e(),_c('b-form-input',{class:`${_vm.$Common.setRequiredErrorBorder(
						_vm.contact_no_state,
						_vm.required_class ? 1 : 0,
						0
					)} ${_vm.hideCallingCode ? '' : 'border-left-0'}`,attrs:{"size":"sm","type":"text","placeholder":_vm.$t('COMMON.PH_ENTER_TEXTBOX', { txt_fieldname: _vm.label }),"autocomplete":"off","disabled":_vm.is_view == 1,"formatter":_vm.$Common.onFormatterPhoneNumber,"lazy-formatter":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},"keypress":_vm.$Common.onPressPhoneNumber},model:{value:(_vm.conctactNo),callback:function ($$v) {_vm.conctactNo=$$v},expression:"conctactNo"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.contact_no_state},domProps:{"textContent":_vm._s(_vm.contact_no_error)}})],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.hidePic == 0),expression:"hidePic == 0"}],staticClass:"cols ml-1"},[_c('label',{staticClass:"text-nowrap mb-0",class:_vm.required_class && !_vm.pic_optional ? `required-fields` : ``,domProps:{"textContent":_vm._s(_vm.label_pic)}}),_c('b-form-input',{class:_vm.$Common.setRequiredErrorBorder(
					_vm.contact_pic_state,
					_vm.required_class && !_vm.pic_optional ? 1 : 0,
					0
				),attrs:{"size":"sm","placeholder":_vm.$t('COMMON.PH_ENTER_TEXTBOX', { txt_fieldname: _vm.label_pic }),"autocomplete":"nope","disabled":_vm.is_view == 1,"formatter":(v) => {
					return _vm.$Common.onFormatterUpperCase(v);
				},"lazy-formatter":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}},model:{value:(_vm.conctactNoPic),callback:function ($$v) {_vm.conctactNoPic=$$v},expression:"conctactNoPic"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.contact_pic_state},domProps:{"textContent":_vm._s(_vm.contact_pic_error)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }