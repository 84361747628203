<template>
	<div>
		<v-select
			:value="data"
			@change="onChange"
			:items="options"
			item-text="calling_code"
			item-value="calling_code"
			hide-details
			dense
			outlined
			class="phone-code-select-box bg-light rounded-right-0"
			:class="getClass()"
			:disabled="is_view == 1"
		>
			<template v-slot:item="{ item }">
				<div
					class="callingCodeOptions flex-grow-1 d-flex justify-space-between"
					:data-desc="`${item.country_name}  `"
				>
					<div class="mr-1" v-text="item.country_name"></div>
					<div v-text="item.calling_code"></div>
				</div>
			</template>
		</v-select>
	</div>
</template>

<script>
	export default {
		name: "PhoneCodeSelect",
		props: {
			data: {
				type: String,
				default: null,
			},
			options: {
				type: Array,
				default() {
					return [];
				},
			},
			error_class: {
				type: Boolean,
				default: false,
			},
			required_class: {
				type: Boolean,
				default: false,
			},
			is_view: {
				type: Number,
				default: 1,
			},
			idx: {
				type: Number,
				default: null,
			},
		},
		data() {
			return {
				callingCodeSelection: [],
			};
		},
		methods: {
			onChange($event) {
				this.$emit("change");
				if (this.idx != null) {
					this.$emit("changeValue", $event, this.idx);
				} else {
					this.$emit("changeValue", $event);
				}
			},
			getClass() {
				if (this.error_class == true) {
					return `error-border`;
				} else if (this.required_class == true) {
					return `required-border`;
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scope>
	div.phone-code-select-box {
		font-size: 0.925rem !important;
		border: 1px solid rgb(192, 192, 192) !important;
	}

	div.phone-code-select-box.v-input--is-disabled {
		color: #3f4254 !important;
	}

	div.phone-code-select-box.v-text-field.error-border {
		border: 1px solid red;
	}

	div.phone-code-select-box.v-text-field.error-border fieldset,
	div.phone-code-select-box.v-text-field.required-border fieldset {
		border: none !important;
	}

	div.phone-code-select-box.v-text-field.required-border {
		border: 1px solid slateblue !important;
	}

	div.phone-code-select-box.v-text-field.v-input--dense
		> .v-input__control
		> .v-input__slot {
		min-height: 18px !important;
	}

	div.phone-code-select-box
		> .v-input__control
		> .v-input__slot
		> .v-select__slot
		> .v-input__append-inner {
		margin-top: 2px !important;
		margin-bottom: 0 !important;
	}

	div.phone-code-select-box.v-select.v-text-field
		> .v-input__control
		> .v-input__slot
		> .v-select__slot
		> .v-select__selections
		> input[type="text"] {
		width: 1px !important;
	}

	div.phone-code-select-box.v-text-field--outlined fieldset {
		/*border: 1px solid #e4e6ef;*/
		border: none !important;
		padding: 0;
	}

	div.phone-code-select-box.v-text-field.v-text-field--enclosed:not(
			.v-text-field--rounded
		)
		> .v-input__control
		> .v-input__slot {
		padding: 0 !important;
	}

	div.phone-code-select-box.v-select.v-input--dense
		.v-select__selection--comma {
		white-space: nowrap !important;
		margin: 2px 4px 0 5px !important;
	}

	div.phone-code-select-box input {
		height: 0 !important;
	}

	div.phone-code-select-box.v-select.v-text-field--outlined:not(
			.v-text-field--single-line
		).v-input--dense
		.v-select__selections {
		margin-top: 0 !important;
		padding: 0 !important;
	}
</style>
