<template>
	<b-row :class="admin_portal == 1 ? `phoneFieldsAdmin` : `phoneFields`">
		<b-col class="cols">
			<label
				v-show="hideLabel == 0"
				class="text-nowrap mb-0"
				v-text="label"
				:class="required_class ? `required-fields` : ``"
			></label>
			<div class="d-flex flex-nowrap">
				<b-button
					v-if="show_edit_button == 1"
					size="sm"
					@click="$emit('blockBtn')"
					:variant="block == 1 ? `success` : `secondary`"
					class="btn-icon mr-1"
					:title="
						block == 1 ? $t('COMMON.BTN_ENABLE_EDIT') : $t('COMMON.BTN_DISABLE_EDIT')
					"
					v-b-tooltip.hover
				>
					<b-icon-pencil-fill></b-icon-pencil-fill>
				</b-button>
				<b-input-group class="flex-grow-1 flex-nowrap">
					<phoneCodeSelect
						:data="callingCode"
						:options="callingCodeSelection"
						:is_view="is_view == 1 || disabled_calling_code == 1 ? 1 : 0"
						:required_class="admin_portal == 1 ? required_class : null"
						:idx="idx"
						@changeValue="changeValue"
						v-if="!hideCallingCode"
					></phoneCodeSelect>
					<b-form-input
						size="sm"
						type="text"
						v-model="conctactNo"
						:placeholder="$t('COMMON.PH_ENTER_TEXTBOX', { txt_fieldname: label })"
						@keydown.enter.prevent
						autocomplete="off"
						:disabled="is_view == 1"
						@keypress="$Common.onPressPhoneNumber"
						:formatter="$Common.onFormatterPhoneNumber"
						lazy-formatter
						:class="`${$Common.setRequiredErrorBorder(
							contact_no_state,
							required_class ? 1 : 0,
							0
						)} ${hideCallingCode ? '' : 'border-left-0'}`"
					></b-form-input>
				</b-input-group>
			</div>
			<b-form-invalid-feedback
				:state="contact_no_state"
				v-text="contact_no_error"
			></b-form-invalid-feedback>
		</b-col>
		<b-col class="cols ml-1" v-show="hidePic == 0">
			<label
				v-text="label_pic"
				class="text-nowrap mb-0"
				:class="required_class && !pic_optional ? `required-fields` : ``"
			></label>
			<b-form-input
				size="sm"
				:placeholder="$t('COMMON.PH_ENTER_TEXTBOX', { txt_fieldname: label_pic })"
				v-model="conctactNoPic"
				@keydown.enter.prevent
				autocomplete="nope"
				:disabled="is_view == 1"
				:class="
					$Common.setRequiredErrorBorder(
						contact_pic_state,
						required_class && !pic_optional ? 1 : 0,
						0
					)
				"
				:formatter="
					(v) => {
						return $Common.onFormatterUpperCase(v);
					}
				"
				lazy-formatter
			></b-form-input>
			<b-form-invalid-feedback
				:state="contact_pic_state"
				v-text="contact_pic_error"
			></b-form-invalid-feedback>
		</b-col>
	</b-row>
</template>

<style scoped>
div.phoneFields input {
	height: 29px !important;
}

div.phoneFieldsAdmin input {
	height: 28px !important;
}

div.phoneFields,
div.phoneFieldsAdmin {
	margin: 0 !important;
	flex-wrap: nowrap !important;
	padding: 0 !important;
}

div.phoneFields > div.cols,
div.phoneFieldsAdmin > div.cols {
	padding: 0 !important;
}
</style>

<script>
import phoneCodeSelect from "@shared/src/components/phoneCodeSelect.vue";

export default {
	name: "PhoneField",
	components: {
		phoneCodeSelect,
	},
	computed: {
		callingCode: {
			get() {
				return this.calling_code;
			},
			set(newVal) {
				if (this.idx != null) {
					this.$emit("updateContactCallingCode", newVal, this.idx);
				} else {
					this.$emit("updateContactCallingCode", newVal);
				}
			},
		},
		conctactNo: {
			get() {
				return this.contact_no;
			},
			set(newVal) {
				if (this.idx != null) {
					this.$emit("updateContact", newVal, this.idx);
				} else {
					this.$emit("updateContact", newVal);
				}
			},
		},
		conctactNoPic: {
			get() {
				return this.contact_no_pic;
			},
			set(newVal) {
				if (this.idx != null) {
					this.$emit("updateContactPIC", newVal, this.idx);
				} else {
					this.$emit("updateContactPIC", newVal);
				}
			},
		},
	},
	props: {
		hideCallingCode: {
			type: Boolean,
			default() {
				return false;
			},
		},
		calling_code: null,
		callingCodeSelection: null,
		is_view: {
			type: Number,
			default: 0,
		},
		required_class: {
			type: Boolean,
			default: false,
		},
		error_class: {
			type: Boolean,
			default: false,
		},
		contact_no: null,
		contact_no_pic: null,
		contact_no_state: null,
		contact_no_error: null,
		contact_pic_state: null,
		contact_pic_error: null,
		label: null,
		label_pic: null,
		disabled_calling_code: {
			type: Number,
			default() {
				return 0;
			},
		},
		hidePic: {
			type: Number,
			default() {
				return 0;
			},
		},
		hideLabel: {
			type: Number,
			default() {
				return 0;
			},
		},
		admin_portal: {
			type: Number,
			default() {
				return 0;
			},
		},
		idx: {
			type: Number,
			default: null,
		},
		show_edit_button: {
			type: Number,
			default() {
				return 0;
			},
		},
		block: {
			type: Number,
			default() {
				return 0;
			},
		},
		pic_optional: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	methods: {
		changeValue($event, idx = null) {
			if (idx != null) {
				this.$emit("updateContactCallingCode", $event, idx);
			} else {
				this.$emit("updateContactCallingCode", $event);
			}
		},
	},
};
</script>
